export const settingsAdmin = [
  {
    header: 'Settings',
  },
  {
    title: 'Company',
    route: 'company-list',
    icon: 'LayoutIcon',
  },
  {
    title: 'User',
    route: 'user-list',
    icon: 'UserIcon',
  },
  {
    title: 'Projects',
    route: 'project-list',
    icon: 'SlidersIcon',
  },
  {
    title: 'Media',
    route: 'media-list',
    icon: 'MonitorIcon',
  },
  {
    title: 'Reports',
    route: 'game-report',
    icon: 'PieChartIcon',
  },
]

export const settingsCompanyAdmin = [
  {
    header: 'Settings',
  },
  {
    title: 'User',
    route: 'user-list',
    icon: 'UserIcon',
  },
  {
    title: 'Media',
    route: 'media-list',
    icon: 'MonitorIcon',
  },
  {
    title: 'Projects',
    route: 'project-list',
    icon: 'SlidersIcon',
  },
]
export const settingsUser = [
  {
    header: 'Settings',
  },
  {
    title: 'Projects',
    route: 'project-list',
    icon: 'SlidersIcon',
  },
]
